<template>
  <div>
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-inner-header
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      redirect="/templates/products"
    />
    <v-add-tax-modal
      :show.sync="isShowTaxModal"
      @close="closeTaxModal"
      @saved="savedTaxModal"
    />
    <v-video-modal
      name="overview"
      title="Product template"
      description="A video to help you get started."
      step="product-template"
      thumbnail="templates.png"
      embed="sodsXLKpvi4"
      :show.sync="isShowTutorialVideoModal"
      @close="closeTutorialVideoModal"
    />
    <div class="max-w-lg mx-auto px-4">
      <div class="flex -mx-2 mt-24 mb-10 pb-4">
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-else
          id="lead-form-setting"
          class="w-full px-2"
        >
          <div class="bg-white pt-8 rounded">
            <div class="px-8">
              <div class="flex items-center justify-between mb-2">
                <div class="w-1/2 md:w-2/3">
                  <h1 class="text-xl">
                    New product for proposal and invoice
                  </h1>
                </div>
                <div class="w-1/2 md:w-1/3 text-right">
                  <div class="inline-block">
                    <v-tutorial-button @click="showTutorialVideoModal" />
                  </div>
                </div>
              </div>
              <p class="my-5 text-grey-darker text-sm">
                Saved products can be selected in your proposal and invoice
              </p>
              <div class="input-group">
                <label>Product name</label>
                <input
                  v-model="name"
                  v-validate="{ required: true }"
                  type="text"
                  class="w-full"
                  name="name"
                  @blur="checkProductName"
                >
                <span class="error">{{ errors.first('name') }}</span>
              </div>
              <div class="input-group">
                <label>Product description</label>
                <textarea
                  v-model="description"
                  class="w-full"
                  name="description"
                />
              </div>
              <table>
                <tr>
                  <td><label class="pr-8">Allow client to pick a quantity</label></td>
                  <td>
                    <v-checkbox
                      id="is_quantity_selectable"
                      v-model="is_quantity_selectable"
                      disable-label="true"
                    />
                  </td>
                </tr>
                <tr>
                  <td><label class="w-24">Amount</label></td>
                  <td class="pt-4">
                    <vue-numeric
                      v-model="amount"
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      class="small-right mr-1"
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :max="100000"
                    />
                    <div class="input-group inline-block-child mt-4 mb-2">
                      <span class="error">{{ errors.first('amount') }}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><label class="pr-8">Tax</label></td>
                  <td>
                    <v-checkbox
                      id="isTaxEnable"
                      v-model="isTaxEnable"
                      disable-label="true"
                    />
                    <div
                      v-if="isTaxEnable"
                      class="inline-block"
                    >
                      <div
                        v-if="hasTaxes"
                        class="inline-block"
                      >
                        <div
                          class="select-container"
                        >
                          <select v-model="taxPercent">
                            <option
                              v-for="(item, index) in taxes"
                              :key="index"
                              :value="item.value"
                              :selected="(taxPercent == item.value) ? 'selected' : ''"
                            >
                              {{ item.value }}%
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        v-else
                        class="inline-block"
                      >
                        <a
                          class="cursor-pointer text-green underline"
                          @click="showTaxModal"
                        >
                          Add a tax class
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>


              <div class="mt-5 input-group inline-block-child pb-8">
                <label class="w-24" />
              </div>
              <!-- <div class="input-group inline-block-child">
                <label class="w-24">Quantity</label>
                <input type="text" class="w-full text-right" v-model="quantity" v-validate="{ required: true, min_value: 1 }" name="quantity">
                <span class="error">{{ errors.first('quantity') }}</span>
              </div> -->
            </div>
            <!--  <div class="border-t border-grey-lighter pt-4 mt-6">
              <div class="px-8 pb-4">
                <checkbox v-model="isAdditionalItem" id="isAdditionalItem" label="Save as additional item"></checkbox>
              </div>
            </div> -->
          </div>
          <div class="mt-5 mb-10 text-right">
            <button
              v-show="!isSaving"
              class="green-btn"
              @click="save"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'

export default {
  name: 'TemplatesProduct',
	data(){
    	return {
        metaTitle: 'Loading',
        currency:{
          sign: '€'
        },
        isSaving: false,
        isSaved: false,
        loading: false,
        hasTaxes: false,
        productId: null,
        name: '',
        nameError: false,
        description: '',
        amount: 0.00,
        taxes: null,
        isTaxEnable: false,
        is_quantity_selectable: false,
        taxPercent: 21,
        isAdditionalItem: false,
        quantity: 1,
        isShowTaxModal: false,
        user: auth.user(),
        isShowTutorialVideoModal: false,
    	}
	},
  async mounted(){
    this.currency = this.user.companySettings.currency
    this.taxes = this.user.companySettings.taxes

    if(this.taxes){
      if(this.taxes[0] !== undefined){
        this.hasTaxes = true
        this.taxPercent = this.taxes[0].value
      }
    }

    if(this.$route.params.templateId){
      this.loading = true
      await this.getData(this.$route.params.templateId)
      this.metaTitle = `Template Product: ${this.name}`
    } else {
      this.metaTitle = 'New Template Product'
    }
  },
	methods: {
    showTutorialVideoModal(){
      this.isShowTutorialVideoModal = true
    },
    closeTutorialVideoModal(){
      this.isShowTutorialVideoModal = false
    },
    async checkProductName(){

      try {
        const postData = {
          companyId: this.user.company.id,
          productId: this.productId,
          name: this.name,
        }

        const { data } = await this.$api.get('templateProduct').checkProductName(postData)

        if(data.product){
          this.nameError = true
          this.errors.add({
            field: 'name',
            msg: 'Product name is already in use.'
          })
        } else {
          this.nameError = false
        }

      } catch(e){
        logException(e)
      }
    },
    save() {
      this.checkProductName()

      if(!this.nameError){
        this.$validator.validate().then(result => {
          if(result){
            this.doSave()
          }
        })
      }
    },
    async doSave() {

      this.saving = true

      try {
        const postData = {
          companyId: this.user.company.id,
          productId: this.productId,
          name: this.name,
          description: this.description,
          amount: this.amount,
          isTaxEnable: this.isTaxEnable,
          tax: this.taxPercent,
          is_quantity_selectable: this.is_quantity_selectable,
          isAdditionalItem: this.isAdditionalItem,
          quantity: this.quantity,
        }

        const { data } = await this.$api.get('templateProduct').updateOrCreate(postData)

        this.productId = data.product.id
        this.isSaved = true
        this.saving = false

        this.$toasted.global.general_success({
          message : 'Product template updated.'
        })

      } catch(e){
        logException(e)
      }
    },
    changeView(view){
      this.view = view
    },
    async getData(id){

      this.loading = true

      try {
        const { data } = await this.$api.get('templateProduct').detail(this.user.company.id, id)
          this.productId = data.product.id
          this.name = data.product.name
          this.description = data.product.description
          this.amount = data.product.price
          this.isTaxEnable = data.product.is_taxable

          if(data.product.tax > 0){
            this.taxPercent = data.product.tax
          }

          this.is_quantity_selectable = data.product.is_quantity_selectable
          this.isAdditionalItem = data.product.is_optional
          this.quantity = data.product.quantity
          this.loading = false
      } catch(e){
        logException(e)
      }
    },
    showTaxModal(){
      this.isShowTaxModal = true
    },
    closeTaxModal(){
      this.isShowTaxModal = false
    },
    async savedTaxModal(taxes, defaultTaxPercent){
      this.isShowTaxModal = false
      this.isTaxEnable = true
      this.taxes = taxes
      this.taxPercent = defaultTaxPercent
      this.$modal.hide('addTaxModal')
    },
	},
}
</script>
